<template>
  <div>
    <div class="busWin" v-if="isOtherImage" style="background-color: black">
      <dv-border-box-8>
        <el-button class="btn" type="primary" @click="isOtherImage = false"
          >X</el-button
        >
        <h1 class="title">{{ busDetail.shop_name }}商家其他材料</h1>
        <div style="max-height: 90%; overflow-y: scroll">
          <table border="2">
            <tr v-for="item in busDetail.industry_image" :key="item.id">
              <th>{{ item.title }}</th>
              <td>
                <img
                  style="max-width: 100%; max-height: 690px; margin: auto"
                  :src="item.uploadImgList[0]"
                />
              </td>
            </tr>
          </table>
        </div>
      </dv-border-box-8>
    </div>
    <div class="busWin">
      <dv-border-box-8>
        <el-button class="btn" type="primary" @click="onClose">X</el-button>
        <div v-if="loading" style="height: 100%">
          <dv-loading style="margin: auto">数据加载中...</dv-loading>
        </div>
        <div v-if="!loading && !hadData" style="height: 100%">
          <div style="padding: 30% 0; font-size: 46px">暂无商家信息！</div>
        </div>
        <div v-if="!loading && hadData" style="height: 100%">
          <h1 class="title">
            {{ busDetail.shop_name }}商家详情
            <a
              style="color: rgb(42, 150, 252); cursor: pointer"
              v-if="
                busDetail.industry_image &&
                busDetail.industry_image.length !== 0
              "
              @click="isOtherImage = true"
            >
              (查看材料)
            </a>
          </h1>
          <div>
            <table border="2">
              <tr>
                <td class="img-td" rowspan="3">
                  <img
                    v-if="busDetail.shop_image.length !== 0"
                    :src="busDetail.shop_image[0]"
                  />
                </td>
                <th>负责人</th>
                <td>{{ busDetail.name }}</td>
                <th>联系方式</th>
                <td>{{ busDetail.phone }}</td>
              </tr>
              <tr>
                <th>评分</th>
                <td :class="busLevel(gradeKind)">
                  {{ busDetail.score / 100 }}
                </td>
                <th>等级</th>
                <td :class="busLevel(gradeKind)">
                  {{ busDetail.level }}
                </td>
              </tr>
              <tr>
                <th>规模</th>
                <td>{{ busDetail.scale }}</td>
                <th>类型</th>
                <td>{{ busType }}</td>
              </tr>
              <tr>
                <th>商店门头照</th>
                <th>经营状况</th>
                <td>{{ busDetail.shop_status }}</td>
                <th>最后排查员</th>
                <td>{{ busDetail.checker }}</td>
              </tr>
              <tr>
                <td class="img-td" rowspan="3">
                  <img
                    v-if="busDetail.qr_image !== ''"
                    :src="busDetail.qr_image"
                  />
                </td>
                <th>拒签</th>
                <td>{{ busDetail.refuse_sign }}</td>
                <th>拒查</th>
                <td>{{ busDetail.refuse_check }}</td>
              </tr>
              <tr>
                <th>所在区域</th>
                <td>{{ busDetail.town }}</td>
                <th>所在村居</th>
                <td>{{ busDetail.village }}</td>
              </tr>
              <tr>
                <th rowspan="2">排查隐患</th>
                <td rowspan="2" colspan="3">
                  <div v-if="dangerListData.data.length === 0">
                    暂无隐患数据
                  </div>
                  <dv-scroll-board
                    v-else
                    style="width: 100%; height: 350px"
                    :config="dangerListData"
                  />
                </td>
              </tr>
              <tr>
                <th>消安码</th>
              </tr>
            </table>
          </div>
        </div>
      </dv-border-box-8>
    </div>
  </div>
</template>

<script>
import { getBusDetail } from "@/api/common";
export default {
  name: "busDetail",
  data() {
    return {
      loading: false,
      hadData: false,
      isOtherImage: false,
      gradeKind: 1,
      busDetail: {
        shop_name: "",
        name: "", //负责人
        phone: "", //负责人电话
        score: 0,
        level: "",
        scale: "",
        shop_status: "",
        town: "",
        village: "",
        shop_type: [],
        refuse_check: "",
        refuse_sign: "",
        checker: "",
        shop_image: [],
        qr_image: "",
        danger: [],
        // score_info: [], // 增加等级制后，评分和等级都从这儿拿
        industry_image: [], // 其他材料
        results: [], //多份排查报告的分数
      },
      //隐患指引列表
      dangerListData: {
        data: [],
        columnWidth: [50],
        align: ["center", "left"],
      },
    };
  },
  computed: {
    busType() {
      if (this.busDetail.shop_type.length !== 0) {
        return this.busDetail.shop_type.join("、");
      }
      return "";
    },
    busLevel() {
      return function (kind) {
        let temp = "level";

        if (kind === 1) {
          switch (this.busDetail.level) {
            case "A":
              temp += " a-score";
              break;
            case "B":
              temp += " b-score";
              break;
            case "C":
              temp += " c-score";
              break;
            default:
              temp += "other-level";
              break;
          }
        }
        if (kind === 2) {
          switch (this.busDetail.level) {
            case "A":
              temp += " a-level";
              break;
            case "B":
              temp += " b-level";
              break;
            case "C":
              temp += " c-level";
              break;
            case "D":
              temp += " d-level";
              break;
            case "E":
              temp += " e-level";
              break;
            default:
              temp += "other-level";
              break;
          }
        }
        return temp;
      };
    },
  },
  methods: {
    async init(id, grade_kind, score) {
      this.loading = true;
      this.gradeKind = grade_kind;
      getBusDetail(id, grade_kind).then((res) => {
        if (res.code !== 0) {
          this.hadData = false;
          this.loading = false;
          return;
        }
        for (let key in this.busDetail) {
          for (let el in res.result) {
            if (key === el) this.busDetail[key] = res.result[el];
          }
          if (key === "results") {
            let tempScoreInfo = {};
            if (this.busDetail[key].length > 1) {
              let tempBusDetail = this.busDetail[key].find(
                (item) => item.score === score
              );
              if (tempBusDetail === -1) {
                tempScoreInfo = this.busDetail[key][0];
              } else {
                tempScoreInfo = tempBusDetail;
              }
            } else {
              tempScoreInfo = this.busDetail[key][0];
            }
            tempScoreInfo.danger.forEach((item, index) => {
              this.dangerListData.data.push([index + 1 + ".", item]);
            });
            this.busDetail.checker = tempScoreInfo.checker;
            this.busDetail.danger = tempScoreInfo.danger;
            this.busDetail.grade_kind = tempScoreInfo.grade_kind;
            this.busDetail.level = tempScoreInfo.level;
            this.busDetail.score = tempScoreInfo.score;
            this.busDetail.refuse_check = tempScoreInfo.refuse_check;
            this.busDetail.refuse_sign = tempScoreInfo.refuse_sign;
          }
        }
        if (this.busDetail.refuse_check === "是") {
          this.dangerListData.data = [];
        }
        this.dangerListData = { ...this.dangerListData };
        this.hadData = true;
        this.loading = false;
      });
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.busWin {
  margin: 1% 5%;
  width: 90%;
  height: 98%;
  background-color: black;
  text-align: center;
  color: white;
  z-index: 993;
}
.title {
  width: 100%;
  padding-top: 10px;
  line-height: 80px;
  text-align: center;
}
.btn {
  position: absolute;
  right: 0;
  font-size: 16px;
  margin: 10px;
}
.img-td {
  width: 66%;
}
.img-td img {
  margin: auto;
  max-width: 100%;
  max-height: 420px;
}
table {
  margin: auto;
  width: 94%;
  height: 90%;
  border-color: rgb(103, 169, 255);
}
th {
  width: 8%;
  font-size: larger;
  font-weight: bolder;
  padding: 8px;
}
td {
  font-size: larger;
}
.level {
  font-weight: 2100;
  font-size: 46px;
}
.a-score {
  color: rgb(123, 255, 79);
}
.b-score {
  color: rgb(255, 146, 51);
}
.c-score {
  color: red;
}
.a-level {
  color: rgb(68, 252, 51);
}
.b-level {
  color: rgb(0, 187, 255);
}
.c-level {
  color: rgb(255, 248, 51);
}
.d-level {
  color: orange;
}
.e-level {
  color: red;
}
.other-level {
  color: rgb(125, 125, 125);
}
/deep/ .dv-scroll-board .rows .row-item {
  font-size: 18px;
}
</style>
L
