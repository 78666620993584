<template>
  <div>
    <!--      区域数据概况        -->
    <dv-border-box-12 backgroundColor="black">
      <div>
        <div class="title">
          <span>区域数据概况</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div
          class="d-flex"
          style="
            justify-content: space-around;
            text-align: center;
            margin-top: 5%;
          "
        >
          <div>
            <!-- <dv-decoration-9 class="decoration-9">
              {{ areaDataDetail.checkTotal }}
            </dv-decoration-9> -->
            <dv-border-box-1
              style="
                width: 200px;
                height: 144px;
                margin: auto auto 6% auto;
                font-size: 24px;
              "
            >
              <dv-digital-flop
                :config="areaCheckTotal"
                style="padding-top: 13%"
              />
            </dv-border-box-1>
            <span class="decoration-9Text">区域累计监管量</span>
          </div>
          <div style="text-align: center">
            <!-- <dv-decoration-9 class="decoration-9">
              {{ areaDataDetail.clearTotal }}
            </dv-decoration-9> -->
            <dv-border-box-1
              style="
                width: 200px;
                height: 144px;
                margin: auto auto 6% auto;
                font-size: 24px;
              "
            >
              <dv-digital-flop
                :config="areaCleanTotal"
                style="padding-top: 13%"
              />
            </dv-border-box-1>
            <span class="decoration-9Text">区域累计隐患消除量</span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <div class="title">
            <span>区域监管排行</span>
            <dv-decoration-1 class="dv-dec-3" />
          </div>
        </div>
        <div class="d-flex">
          <div
            v-if="!areaWatchData.data || areaWatchData.data.length === 0"
            class="emptyTips"
          >
            暂无数据
          </div>
          <dv-scroll-board v-else :config="areaWatchData" class="dataTable" />
        </div>
      </div>
    </dv-border-box-12>
    <!--      行业部门数据概况        -->
    <dv-border-box-12 backgroundColor="black">
      <div>
        <div class="title">
          <span>行业部门数据概况</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div
          class="d-flex"
          style="
            justify-content: space-around;
            text-align: center;
            margin-top: 5%;
          "
        >
          <div>
            <!-- <dv-decoration-9 class="decoration-9">
              {{ departmentDataDetail.checkTotal }}
            </dv-decoration-9> -->
            <dv-border-box-10
              style="
                width: 200px;
                height: 144px;
                margin: auto auto 6% auto;
                font-size: 24px;
              "
            >
              <dv-digital-flop
                :config="departmentCheckTotal"
                style="padding-top: 13%"
              />
            </dv-border-box-10>
            <span class="decoration-9Text">行业累计监管量</span>
          </div>
          <div>
            <!-- <dv-decoration-9 class="decoration-9">
              {{ departmentDataDetail.clearTotal }}
            </dv-decoration-9> -->
            <dv-border-box-10
              style="
                width: 200px;
                height: 144px;
                margin: auto auto 6% auto;
                font-size: 24px;
              "
            >
              <dv-digital-flop
                :config="departmentCleanTotal"
                style="padding-top: 13%"
              />
            </dv-border-box-10>
            <span class="decoration-9Text">行业累计隐患消除量</span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <div class="title">
            <span>行业监管排行</span>
            <dv-decoration-1 class="dv-dec-3" />
          </div>
        </div>
        <div class="d-flex">
          <div
            v-if="
              !departmentWatchData.data || departmentWatchData.data.length === 0
            "
            class="emptyTips"
          >
            暂无数据
          </div>
          <dv-scroll-board
            v-else
            :config="departmentWatchData"
            class="dataTable"
          />
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import {
  getAreaCount,
  getAreaReviseCount,
  getLowerAreaCount,
  getLowerDepartment,
} from "@/api/common";
import {
  mockAreaData,
  mockAreaDataDetail,
  mockDepartmentData,
  mockDepartmentDataDetail,
} from "@/mock/mockData";

function formatter(number) {
  const numbers = number.toString().split("").reverse();
  const segs = [];
  while (numbers.length) segs.push(numbers.splice(0, 3).join(""));
  return segs.join(",").split("").reverse().join("");
}

export default {
  name: "areAndDepartmentRightDataWidthFit.vue",
  props: {
    territory_id: {
      type: String, //类型是字符串
      required: true, //必传项
    },
    isMock: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    territory_id: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.init(newVal, this.unit_id);
        }
      },
    },
    isMock: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.init(this.territory_id, this.unit_id);
        }
      },
    },
  },
  data() {
    return {
      //mock数据
      mockAreaData,
      mockAreaDataDetail,
      mockDepartmentData,
      mockDepartmentDataDetail,
      //区域数据概况
      areaDataDetail: {
        checkTotal: 0,
        clearTotal: 0,
      },
      areaCheckTotal: {
        number: [0],
        content: "{nt}次",
        style: {
          fontSize: 35,
          fill: "#3de7c9",
        },
        formatter,
      },
      areaCleanTotal: {
        number: [0],
        content: "{nt}个",
        style: {
          fontSize: 35,
          fill: "#3de7c9",
        },
        formatter,
      },
      //区域监管排行
      areaWatchData: {
        // header: ['区域', '已排查', '待排查', '完成率', '隐患余量'],
        header: ["区域", "已排查", "解决隐患", "隐患余量"],
        data: [],
        index: true,
        // columnWidth: [50, 140, 110, 110, 110, 140],
        columnWidth: [50, 140, 110, 110, 140],
        // align: ['center', 'center', 'center', 'center', 'center', 'center']
        align: ["center", "center", "center", "center", "center"],
      },
      //行业部门数据概况
      departmentDataDetail: {
        checkTotal: 0,
        clearTotal: 0,
      },
      departmentCheckTotal: {
        number: [0],
        content: "{nt}次",
        style: {
          fontSize: 35,
          fill: "#3de7c9",
        },
        formatter,
      },
      departmentCleanTotal: {
        number: [0],
        content: "{nt}个",
        style: {
          fontSize: 35,
          fill: "#3de7c9",
        },
        formatter,
      },
      //行业部门监管排行
      departmentWatchData: {
        // header: ['部门', '已排查', '待排查', '完成率', '隐患余量'],
        header: ["部门", "已排查", "解决隐患", "隐患余量"],
        data: [],
        index: true,
        columnWidth: [50, 140, 110, 110, 140],
        align: ["center", "center", "center", "center", "center"],
        // columnWidth: [35, 140, 110, 110, 110, 140],
        // align: ['center', 'center', 'center', 'center', 'center', 'center']
      },
    };
  },
  mounted() {
    this.init(this.territory_id);
  },
  methods: {
    init(territory_id) {
      // mock数据
      if (this.isMock) {
        // 区域数据概况
        // this.areaDataDetail.checkTotal = mockAreaDataDetail.checkTotal
        // this.areaDataDetail.clearTotal = mockAreaDataDetail.clearTotal
        this.areaCheckTotal.number[0] = mockAreaDataDetail.checkTotal;
        this.areaCleanTotal.number[0] = mockAreaDataDetail.clearTotal;
        this.areaWatchData.data = mockAreaData;
        //刷新数据
        this.areaCheckTotal = { ...this.areaCheckTotal };
        this.areaCleanTotal = { ...this.areaCleanTotal };
        this.areaWatchData = { ...this.areaWatchData };
        // 行业部门数据概况
        // this.departmentDataDetail.checkTotal = mockDepartmentDataDetail.checkTotal
        // this.departmentDataDetail.clearTotal = mockDepartmentDataDetail.clearTotal
        this.departmentCheckTotal.number[0] =
          mockDepartmentDataDetail.checkTotal;
        this.departmentCleanTotal.number[0] =
          mockDepartmentDataDetail.clearTotal;
        this.departmentWatchData.data = mockDepartmentData;
        //刷新数据
        this.departmentCheckTotal = { ...this.departmentCheckTotal };
        this.departmentCleanTotal = { ...this.departmentCleanTotal };
        this.departmentWatchData = { ...this.departmentWatchData };
        return;
      }
      // 区域数据概况
      this.areaCheckTotal.number[0] = 0;
      getAreaCount(territory_id).then((res) => {
        if (res.code === 0) {
          this.areaCheckTotal.number[0] = res.result.check_count;
          this.areaCheckTotal = { ...this.areaCheckTotal };
        }
      });
      this.areaCleanTotal.number[0] = 0;
      getAreaReviseCount(territory_id).then((res) => {
        if (res.code === 0) {
          this.areaCleanTotal.number[0] = res.result.revise_count;
          this.areaCleanTotal = { ...this.areaCleanTotal };
        }
      });
      let tempAreaData = this.areaWatchData.data = [];
      getLowerAreaCount(territory_id).then((res) => {
        if (res.code === 0) {
          res.result
            .sort((a, b) => {
              return b.check_count - a.check_count;
            })
            .forEach((item) => {
              // let temp = [item.name, item.check, item.uncheck, item.rate + '%', item.no_pass]
              let temp = [
                item.town_name,
                item.check_count,
                item.solve_count,
                item.illegal_count,
              ];
              tempAreaData.push(temp);
            });
        }
        this.areaWatchData.data = tempAreaData;
        this.areaWatchData = { ...this.areaWatchData };
      });
      // 0704前老版本接口
      // getAreaOverview(territory_id,unit_id).then(res => {
      //   if (res.code === 0) {
      //     // this.areaDataDetail.checkTotal = res.result.check_total
      //     // this.areaDataDetail.clearTotal = res.result.clear_total
      //     this.areaCheckTotal.number[0] = res.result.check_total
      //     this.areaCleanTotal.number[0] = res.result.clear_total
      //     res.result.list.sort((a, b) => {
      //       return b.check_total - a.check_total
      //     }).forEach(item => {
      //       // let temp = [item.name, item.check, item.uncheck, item.rate + '%', item.no_pass]
      //       let temp = [item.name, item.check, item.uncheck, item.no_pass]
      //       tempAreaData.push(temp)
      //     })
      //   }
      //   this.areaWatchData.data = tempAreaData
      //   //刷新数据
      //   this.areaCheckTotal = {...this.areaCheckTotal}
      //   this.areaCleanTotal = {...this.areaCleanTotal}
      //   this.areaWatchData = { ...this.areaWatchData }
      // })

      // 行业部门数据概况
      let tempDepartmentData = (this.departmentWatchData.data = []);
      let tempDepCheckTotal = (this.departmentCheckTotal.number[0] = 0);
      let tempDepReviseTotal = (this.departmentCleanTotal.number[0] = 0);
      // getGovDepartmentOverview(territory_id,unit_id).then(res => {
      //   if (res.code === 0) {
      //     // this.departmentDataDetail.checkTotal = res.result.check_total
      //     // this.departmentDataDetail.clearTotal = res.result.clear_total
      //     this.departmentCheckTotal.number[0] = res.result.check_total
      //     this.departmentCleanTotal.number[0] = res.result.clear_total
      //     res.result.list.sort((a, b) => {
      //       return b.check_total - a.check_total
      //     }).forEach(item => {
      //       let temp = [item.name, item.check?item.check:0, item.uncheck?item.uncheck:0, item.rate?(item.rate + '%'): 0 , item.no_pass?item.no_pass:0]
      //       tempDepartmentData.push(temp)
      //     })
      //   }
      //   this.departmentWatchData.data = tempDepartmentData
      // })
      getLowerDepartment(territory_id).then((res) => {
        if (res.code === 0 && res.result?.length !== 0) {
          res.result
            .sort((a, b) => {
              return b.check_count - a.check_count;
            })
            .forEach((item) => {
              tempDepartmentData.push([
                item.town_name,
                item.check_count,
                item.solve_count,
                item.illegal_count,
              ]);
              tempDepCheckTotal += item.check_count;
              tempDepReviseTotal += item.solve_count;
            });
          this.departmentWatchData.data = tempDepartmentData;
          this.departmentCheckTotal.number[0] = tempDepCheckTotal;
          this.departmentCleanTotal.number[0] = tempDepReviseTotal;
        }
        //刷新数据
        this.departmentCheckTotal = { ...this.departmentCheckTotal };
        this.departmentCleanTotal = { ...this.departmentCleanTotal };
        this.departmentWatchData = { ...this.departmentWatchData };
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 18px;
  display: flex;
  padding: 18px 0 0 15px;
  color: white;
}

.dv-dec-3 {
  position: relative;
  width: 100px;
  height: 20px;
}

.d-flex {
  display: flex;
}

.decoration-9 {
  width: 144px;
  height: 144px;
  margin: auto auto 6% auto;
  font-size: 24px;
}

.decoration-9Text {
  font-size: 18px;
  margin: auto;
}

.emptyTips {
  margin: auto;
  font-size: 16px;
  line-height: 200px;
}

.dataTable {
  width: 94%;
  height: 230px;
  margin: auto;
}

/deep/ .dv-scroll-board .rows .row-item {
  font-size: 14px !important;
}

/deep/ .dv-scroll-board .rows .ceil {
  padding: 0 5px;
}

/deep/ .dv-scroll-board .header .header-item {
  font-size: 16px !important;
}
</style>
