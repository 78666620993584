import request from '../utils/request';


//登录接口获取token值
function GetToken(params) {
    return request.postF(
        '/api/login', {
            ...params
        }, 'post'
    )
}


// 大屏左上边
function BusinessDicCount(territory_id, unit_id) {
    return request.getF(
        '/api/getbusinessdiccount?territory_id=' + territory_id + '&unit_id=' + unit_id,
        'get'
    )
}


// 大屏右上边
function RulesCount(territory_id, unit_id) {
    return request.getF(
        '/api/getrulescount?territory_id=' + territory_id + '&unit_id=' + unit_id, 'get'
    )
}


// 大屏底部下边
function HiddenDangerCount(territory_id, unit_id) {
    return request.getF(
        '/api/gethiddendangercount?territory_id=' + territory_id + '&unit_id=' + unit_id, 'get'
    )
}

// 获取code值
function getCodeValue() {
    return request.getBefore(
        '/api/getcode', 'get'
    )
}

// 获取token
function getTokenByCode(code) {
    return request.getF(
        '/api/gettokenbycode?code=' + code, 'get'
    )
}

// 大屏中间地图
function busMapData(params) {
    return request.postBig(
        '/api/getmapbus', {
            ...params
        },
        'post'
    )
}

// // 大屏中间地图商事主体详细信息 0705废弃
// function getBusDetail(id) {
//     return request.getF(
//         '/api/getmapbusdetail?bid=' + id, 'get'
//     )
// }

//  商事主体门头照 0705废弃
// function getBusShopImage(id) {
//     return request.getF(
//         '/api/getbusshopimage?bid=' + id, 'get'
//     )
// }

//重点隐患关注分布
function getKeyDangerous(territory_id, unit_id) {
    return request.getF(
        '/api/getlowleftcount?territory_id=' + territory_id + '&unit_id=' + unit_id, 'get'
    )
}

//消防资讯
function getFireInfo(id) {
    return request.getF(
        '/api/getnews?id=' + id, 'get'
    )
}

//紧急资讯
function getDangerousData(id) {
    return request.getF(
        '/api/getcenterrightnotify?id=' + id, 'get'
    )
}


//  区域数据概况
function getAreaOverview(territory_id, unit_id) {
    return request.getF(
        '/api/getupperrightcount?territory_id=' + territory_id + '&unit_id=' + unit_id, 'get'
    )
}

//  行政部门数据概况
function getGovDepartmentOverview(territory_id, unit_id) {
    return request.getF(
        '/api/getlowrightcount?territory_id=' + territory_id + '&unit_id=' + unit_id, 'get'
    )
}

/*
* 20230704新接口
*/
//左上角区域
//根据商事主体类型获取上市主体数量
function getBusTypeCount(territory_id){
    return request.getF(
        '/api/busdicshopcount?territory_id=' + territory_id , 'get'
    )
}
//根据商事主体ABC数量分类
function getShopLevelCount(territory_id){
    return request.getF(
        '/api/abcshopcounttime?territory_id=' + territory_id , 'get'
    )
}

//根据七大类获取数据
function getDangerTypeCount(territory_id){
    return request.getF(
        '/api/dangercountbyseven?territory_id=' + territory_id , 'get'
    )
}
//重点关注隐患
function getKeyDangerCount(territory_id){
    return request.getF(
        '/api/focusdangercount?territory_id=' + territory_id , 'get'
    )
}

//右上角区域
//右上角区域排查数
function getAreaCount(territory_id) {
    return request.getF(
        '/api/checkcount?territory_id=' + territory_id , 'get'
    )
}

//右上角隐患整改次数
function getAreaReviseCount(territory_id) {
    return request.getF(
        '/api/solvecount?territory_id=' + territory_id , 'get'
    )
}
//右上角隐患整改次数
function getLowerAreaCount(territory_id) {
    return request.getF(
        '/api/countunderarea?territory_id=' + territory_id , 'get'
    )
}
//右下角
// 获取行业部门数据
function getLowerDepartment(territory_id) {
    return request.getF(
        '/api/industryunderarea?territory_id=' + territory_id , 'get'
    )
}
//弹窗相关
//商家详细信息
function getBusDetail(business_id,grade_kind) {
    return request.getF(
        '/api/business?business_id=' + business_id +'&grade_kind='+ grade_kind , 'get'
    )
}

//230912新接口
//获取等级参数
function getScoreLevel() {
    return request.getF(
        '/api/scorelevelparam', 'get'
    )
}

//231221新接口
//获取等级参数
function getSSEToken(params) {
    return request.postBig(
        '/api/maptoken', {
            ...params
        },
        'post'
    )
}
//获取等级参数
function getSSE(token,page) {
    return request.getF(
        '/api/sse?token='+token+'&page='+page, 'get'
    )
}

//获取任务标签
function getTaskLab() {
    return request.getF(
        '/api/tasklabs', 'get'
    )
}

export {
    GetToken,
    BusinessDicCount,
    RulesCount, 
    HiddenDangerCount,
    busMapData,
    getBusDetail,
    getCodeValue,
    getTokenByCode,
    getKeyDangerous,
    // getBusShopImage,
    getFireInfo,
    getDangerousData,
    getAreaOverview,
    getGovDepartmentOverview,
    //0704后
    getAreaCount,
    getShopLevelCount,
    getDangerTypeCount,
    getKeyDangerCount,
    getBusTypeCount,
    getAreaReviseCount,
    getLowerAreaCount,
    getLowerDepartment,
    //0912后
    getScoreLevel,
    //1221后
    getSSEToken,
    getSSE,
    //20240315
    getTaskLab,
}