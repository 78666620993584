/**    左边    **/
// 商事主体统计
export const mockBusTypeData = [
    { name: '三小场所', value: 5412 },
    { name: '教育', value: 541 },
    { name: '餐饮业', value: 1221 },
    { name: '娱乐业', value: 2341 },
    { name: '商场', value: 12 },
    { name: '小娱乐场所', value: 312 },
    { name: '小档口', value: 521 },
];
// 商事主体综合评价
export const mockMarkTypesData = [
    { name: 'A类', value: 6542 },
    { name: 'B类', value: 251},
    { name: 'C类', value: 350}
];
// 隐患分类统计
export const mockDangerousClassifyData = [
    { name: '隐患评分', value: 6752 },
    { name: '建筑防火', value: 5421 },
    { name: '消防设施', value: 3221 },
    { name: '消防管理', value: 1341 },
    { name: '典型隐患', value: 1009 },
    { name: '消防通道', value: 532 },
    { name: '培训演练', value: 21 },
];
// 重点隐患关注分布
export const mockKeyDangerousData = [
    ['有无违规住人', 58, '3.5%'],
    ['电动车充电', 32, '1.1%'],
    ['未存放易燃易爆物品', 21, '1%'],
    ['紧急逃生通道畅通', 8, '0.5%'],
    ['是否安装联网式烟感、喷淋', 4, '0.25%'],
];
/**    中间    **/
// 紧急资讯
export const mockEmergencyMessage = [
    { message: '1、阳山县江英镇心远运输服务部存在重点关注隐患！' },
    { message: '2、英德市壹壹网络科技有限公司存在重点关注隐患！' },
    { message: '3、阳山县江英镇心远运输服务部存在重点关注隐患！' },
    { message: '4、连南瑶族自治县安速家用电器维修部存在重点关注隐患！' },
    { message: '5、连山壮族瑶族自治县永和镇向阳村黎屋第九经济合作社存在重点关注隐患！' },
    { message: '6、佛冈县展辉农业专业合作社存在重点关注隐患！' },
    { message: '7、清远市清新区太和镇富邦达建材商行存在重点关注隐患！' },
]
// 消防资讯
// id随便，不重复就好；
// image给我图片，我打包进项目；
// name是小标题
// 子荣回复：私发你，标题先写着
export const mockFireMessage = [
    {
        id: "1",
        image: require('../../src/assets/img/pic5.png'),
        name: "元宵节火灾同比上升88.8％！",
    },
    {
        id: "2",
        image: require('../../src/assets/img/pic6.png'),
        name: "突发山林火灾 武警官兵火速救援",
    }
]

/**    右边    **/
// 区域监管排行
export const mockAreaDataDetail = {
    checkTotal: 502360,
    clearTotal: 1573
}
export const mockAreaData = [
    ['广州市', 133254, 684, 326],
    ['佛山市', 145241, 518, 216],
    ['清远市', 88541, 45121, 312],
    ['潮州市', 54124, 22145, 452],
    ['云浮市', 45556, 12423, 122],
    ['韶关市', 35644, 4535, 145],
];
// export const mockAreaData = [
//     ['广州市', 133254, 684, '98%', 326],
//     ['佛山市', 145241, 518, '98%', 216],
//     ['清远市', 88541, 45121, '56%', 312],
//     ['潮州市', 54124, 22145, '48%', 452],
//     ['云浮市', 45556, 12423, '32%', 122],
//     ['韶关市', 35644, 4535, '11%', 145],
// ];
// 行业部门数据概况
export const mockDepartmentDataDetail = {
    checkTotal: 1038,
    clearTotal: 1013
}
export const mockDepartmentData = [
    ['广州市教育局', 3242, 151, '98%', 3],
    ['清远市文广旅体局', 5123, 1232, '90%', 12],
    ['清远市洲心街道办', 10251, 1021, '90%', 124],
    ['清城区文广旅体局', 2002, 1250, '58%', 12],
    ['云浮市教育局', 3242, 2321, '30%', 321],
    ['潮州市古城消防站',5411,4512, '25%', 154]
];