<template>
  <div>
    <dv-border-box-12 backgroundColor="black">
      <!--       商事主体统计       -->
      <div style="height: 25%">
        <div class="title">
          <span>商事主体统计</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div class="d-flex">
          <div
            v-if="!busTypeData.data || busTypeData.data.length === 0"
            class="busTypeEmpty"
          >
            暂无数据
          </div>
          <dv-active-ring-chart
            v-else
            :config="busTypeData"
            class="busTypeHasData"
          />
        </div>
      </div>
      <!--      商事主体综合评分        -->
      <div style="height: 25%">
        <div class="title">
          <span>商事主体综合评价</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div class="d-flex">
          <div
            v-if="!markTypesData.data || markTypesData.data.length === 0"
            class="busTypeEmpty"
          >
            暂无数据
          </div>
          <dv-conical-column-chart
            v-else
            :config="markTypesData"
            class="otherTypeHasData"
          />
        </div>
      </div>
      <!--      隐患分类统计      -->
      <div style="height: 25%">
        <div class="title">
          <span>隐患分类统计{{ dangerCardTitle(cardTitle) }}</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div class="d-flex">
          <div
            v-if="
              !dangerousClassifyData.data ||
              dangerousClassifyData.data.length === 0
            "
            class="busTypeEmpty"
          >
            暂无数据
          </div>
          <dv-scroll-ranking-board
            v-else
            :config="dangerousClassifyData"
            class="otherTypeHasData"
          />
        </div>
      </div>
      <!--      重点隐患关注分布        -->
      <div style="height: 25%">
        <div class="title">
          <span>重点隐患关注分布</span>
          <dv-decoration-1 class="dv-dec-3" />
        </div>
        <div class="d-flex">
          <div
            v-if="!keyDangerousData.data || keyDangerousData.data.length === 0"
            class="busTypeEmpty"
          >
            暂无数据
          </div>
          <dv-scroll-board
            v-else
            :config="keyDangerousData"
            class="otherTypeHasData"
          />
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import {
  getBusTypeCount,
  getKeyDangerCount,
  getShopLevelCount,
  getDangerTypeCount,
} from "@/api/common";
import {
  mockBusTypeData,
  mockMarkTypesData,
  mockDangerousClassifyData,
  mockKeyDangerousData,
} from "@/mock/mockData";

export default {
  name: "busLeftDataWidthFit",
  props: {
    territory_id: {
      type: String, //类型是字符串
      required: true, //必传项
    },
    unit_id: {
      type: String,
      required: true,
    },
    isMock: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    territory_id: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.init(newVal, this.unit_id);
        }
      },
    },
    isMock: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.init(this.territory_id, this.unit_id);
        }
      },
    },
  },
  data() {
    return {
      //mock数据
      mockBusTypeData,
      mockMarkTypesData,
      mockDangerousClassifyData,
      mockKeyDangerousData,
      //商事主体统计
      busTypeData: {
        radius: "70%",
        showOriginValue: "true",
        activeRadius: "80%",
        animationFrame:10,
        lineWidth: 38,
        digitalFlopStyle: {
          fontSize: 21,
        },
        data: [],
      },
      //商事主体综合评分
      markTypesData: {
        data: [],
        showValue: true,
      },
      //七大类
      kindNum: 0, //接口返回的种类
      currentKind: 0, //当前展示的种类
      loopTime: 0, //每种类展示时间
      cardTitle: "", //种类卡片标题后缀
      timeOutLoop: undefined, //循环对象
      allKindData: undefined, //处理后的数据
      //隐患分类统计
      dangerousClassifyData: {
        data: [],
      },
      //重点关注隐患分布
      keyDangerousData: {
        header: ["重点隐患", "数量", "占比"],
        data: [],
        index: true,
        columnWidth: [42, 240, 80, 100],
        align: ["center", "center", "center", "center"],
      },
    };
  },
  mounted() {
    this.init(this.territory_id, this.unit_id);
  },
  computed: {
    dangerCardTitle() {
      return (title) => {
        if (title !== "") return `(${title})`;
        return "";
      };
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOutLoop);
  },
  methods: {
    init(territory_id, unit_id) {
      // mock数据
      if (this.isMock) {
        this.busTypeData.data = mockBusTypeData;
        this.busTypeData = { ...this.busTypeData };
        this.markTypesData.data = mockMarkTypesData;
        this.markTypesData = { ...this.markTypesData };
        this.dangerousClassifyData.data = mockDangerousClassifyData;
        this.dangerousClassifyData = { ...this.dangerousClassifyData };
        this.keyDangerousData.data = mockKeyDangerousData;
        this.keyDangerousData = { ...this.keyDangerousData };
        return;
      }
      //商事主体统计
      getBusTypeCount(territory_id).then((res) => {
        this.busTypeData.data = [];
        if (res.code === 0 && res.result) {
          this.busTypeData.data = JSON.parse(
            JSON.stringify(res.result).replace(/"count":/g, '"value":')
          )
            .sort((a, b) => {
              return b.value - a.value;
            })
            .filter((item) => item.value !== 0);
        }
        this.busTypeData = { ...this.busTypeData };
      });
      //商事主体综合评分
      getShopLevelCount(territory_id, unit_id).then((res) => {
        this.markTypesData.data = [];
        if (res.code === 0 && res.result?.length !== 0) {
          this.markTypesData.data = [
            { name: "A类", value: this.findLevelReturnCount(res.result, "A") },
            { name: "B类", value: this.findLevelReturnCount(res.result, "B") },
            { name: "C类", value: this.findLevelReturnCount(res.result, "C") },
          ].filter((item) => item.value !== 0); //组件数值0时报错不显示
        }
        this.markTypesData = { ...this.markTypesData };
      });
      //隐患分类统计
      getDangerTypeCount(territory_id).then((res) => {
        if (res.code === 0 && res.result && res.result.length !== 0) {
          this.kindNum = res.result.length;
          this.loopTime = 300000 / this.kindNum;
          this.allKindData = JSON.parse(
            JSON.stringify(res.result).replace(/"count":/g, '"value":')
          );
          if (this.kindNum !== 1) {//大于两个行业部门类型即调用计时循环器
            this.loopDangerKind(this.currentKind, this.loopTime);
          } else {
            this.cardTitle = this.allKindData[0].big_kind;
            this.dangerousClassifyData.data = this.allKindData[0].list;
            this.dangerousClassifyData = { ...this.dangerousClassifyData };
          }
        }
      });
      //重点隐患关注分布
      let tempFocusData = [];
      getKeyDangerCount(territory_id).then((res) => {
        if (res.code === 0 && res.result) {
          res.result
            .sort((a, b) => {
              return b.count - a.count;
            })
            .forEach((item) => {
              tempFocusData.push([item.name, item.count, item.rate]);
            });
          this.keyDangerousData.data = tempFocusData;
        }
        this.keyDangerousData = { ...this.keyDangerousData };
      });
    },
    //处理商事主体ABC类数据
    findLevelReturnCount(data, level) {
      let temp = data.find((item) => item.score_level === level);
      if (!temp) return 0;
      return temp.count;
    },
    //循环刷新隐患分类统计,传入全局的当前类型和时间，创建循环计时器
    loopDangerKind(kind, time) {
      if (this.timeOutLoop) this.timeOutLoop = undefined;//计时器存在则销毁
      if (kind === this.kindNum) kind = 0;//达到循环最大值则归零
      this.cardTitle = this.allKindData[kind].big_kind;
      this.dangerousClassifyData.data = this.allKindData[kind].list;
      this.dangerousClassifyData = { ...this.dangerousClassifyData };
      this.currentKind = kind + 1;//给计时器传入下一个展示内容
      this.timeOutLoop = setTimeout(() => {
        this.loopDangerKind(this.currentKind, time);
      }, time);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 18px;
  display: flex;
  padding: 18px 0 0 15px;
  color: white;
}

.dv-dec-3 {
  position: relative;
  width: 100px;
  height: 20px;
}

.d-flex {
  display: flex;
}

/deep/.dv-scroll-board .rows .row-item {
  font-size: 16px !important;
}

/deep/.dv-scroll-board .header .header-item {
  font-size: 14px !important;
}

.busTypeEmpty {
  margin: auto;
  line-height: 200px;
}
.busTypeHasData {
  margin: auto;
  width: 94%;
  height: 200px;
}
.otherTypeHasData {
  margin: auto;
  width: 94%;
  height: 210px;
}
</style>
