<template>
  <div class="bg-color" id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-show="loading">Loading...</dv-loading>
      <div class="host-body">
        <!--    临时logo    -->
        <el-image
          v-if="showLogo"
          draggable="false"
          v-show="!loading"
          :class="UIOnShow ? 'sg_logoIn' : 'sg_logoOut'"
          :src="require('../assets/img/la_logo.png')"
        />
        <!--  标题  -->
        <div style="position: relative" class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <!--     区域选择控件     -->
          <div v-show="!isMock" class="d-flex select-left">
            <template>区域：{{ getShowPath(areaId) }} /</template>
          </div>
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span
                v-show="!loading"
                style="
                  width: 600px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
                class="title-text"
                >{{ largeScreenTitle }}</span
              >
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <!--     全屏按钮及其他功能按钮     -->
          <div class="d-flex select-right">
            <div>{{ dateYear }} {{ dateDay }} {{ dateWeek }}</div>
            <i
              class="el-icon-full-screen"
              @click="UIOnShow = !UIOnShow"
              style="margin-left: 5px; color: #3f8cff; z-index: 22"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <!--界面主题-->
        <!--地图-->
        <div id="container" ref="mapRef" />
        <div
          :class="UIOnShow ? 'mapLoadingText_in' : 'mapLoadingText_out'"
          v-if="!finishMapData && !loading"
        >
          地图加载中...
        </div>
        <div
          v-show="!loading"
          :class="UIOnShow ? 'mapButtonIn' : 'mapButtonOut'"
        >
          <el-button
            type="primary"
            icon="el-icon-search"
            circle
            @click="siftSwitch = true"
          ></el-button>
          <div
            style="
              display: flex;
              flex-direction: column;
              padding: 10px 0 0 24px;
            "
          >
            <button class="mapZoomButton" @click="mapZoom('in')">+</button>
            <button class="mapZoomButton" @click="mapZoom('out')">-</button>
          </div>
        </div>
        <div v-if="!loading">
          <!--左列-->
          <transition name="el-fade-in-linear">
            <bus-left-data-width-fit
              :is-mock="isMock"
              style="position: absolute; width: 25%; height: 94.6%; top: 5.7%"
              :territory_id="toMapData"
              :unit_id="toMapUnit"
              v-if="UIOnShow"
              ref="busData"
            />
          </transition>
          <!--右列-->
          <transition name="el-fade-in-linear">
            <are-and-department-right-data-width-fit
              style="
                position: absolute;
                width: 25%;
                height: 47.3%;
                top: 5.7%;
                right: 0;
              "
              :territory_id="toMapData"
              :is-mock="isMock"
              v-if="UIOnShow"
              ref="areaAndDep"
            />
          </transition>
        </div>
      </div>
      <!--   商家详细信息弹窗   -->
      <bus-detail
        v-if="busInfoSwitch && !isMock"
        class="winBg"
        ref="busDetailWin"
        @onClose="onCloseBusWin"
      />
      <!--  筛选按钮弹窗  -->
      <div class="sift_window" v-if="siftSwitch">
        <div class="sift_card">
          <h2 style="margin: 20px auto; text-align: center">地图点筛选项</h2>
          <div style="height: 85%; overflow-y: scroll">
            <div
              v-if="
                !(
                  defaultAreaPath.length -
                    defaultAreaPath.replaceAll('/', '').length ===
                  3
                )
              "
              class="sift_sub_block"
            >
              <div class="sift_sub_block_title">地域选择</div>
              <span style="width: 100%"
                ><span style="margin-left: 10px"
                  >区域：{{ userInfo.path }}</span
                >
                <el-cascader
                  style="width: auto; margin: 5px 0"
                  :options="areaData"
                  v-model="areaId"
                  :props="{
                    checkStrictly: true,
                    emitPath: false,
                    label: 'name',
                    value: 'id',
                  }"
                  clearable
              /></span>
            </div>
            <div v-if="taskLab.length !== 0" class="sift_sub_block">
              <div class="sift_sub_block_title">任务标签选择</div>

              <el-cascader
                style="width: 100%; margin: 5px 0"
                :options="taskLab"
                v-model="task_lab_id"
                :props="{
                  checkStrictly: true,
                  emitPath: false,
                  label: 'name',
                  value: '_id',
                }"
                clearable
              />
            </div>
            <div class="sift_sub_block">
              <div class="sift_sub_block_title">评分类型</div>
              <span v-for="(item, index) in grade_kind_grow" :key="index">
                <button
                  :class="
                    gradeKind === item ? 'buttonSelect' : 'buttonUnSelect'
                  "
                  @click="switchRegulation(item)"
                >
                  {{ gradeKindName(item) }}
                </button>
              </span>
            </div>
            <div class="sift_sub_block">
              <div class="sift_sub_block_title">行业类型</div>
              <div v-if="isLastKind" style="margin-left: 10px; color: red">
                至少选择一个类型
              </div>
              <span v-for="item in bigKind" :key="item.id">
                <button
                  :class="
                    bigKindButtonStyle(item.id)
                      ? 'buttonSelect'
                      : 'buttonUnSelect'
                  "
                  @click="switchDepartment(item.id)"
                >
                  <span style="padding: 0 10px">{{ item.name }}</span>
                </button>
              </span>
            </div>
            <div v-for="(item, key, index) in levelParam" :key="index">
              <div class="sift_sub_block" v-if="item.gradeKind === gradeKind">
                <div class="sift_sub_block_title">
                  {{ levelName[key] }}
                </div>
                <span v-for="el in item" :key="el.name">
                  <button
                    v-if="el.name"
                    :class="
                      buttonStyle(key, el.name)
                        ? 'buttonSelect'
                        : 'buttonUnSelect'
                    "
                    @click="siftScore(key, el.name)"
                  >
                    <span style="padding: 0 10px"> {{ el.name }}</span>
                  </button>
                </span>
              </div>
            </div>
            <div style="margin: -20px 10px">
              <span v-for="(item, key, index) in levelParam" :key="index"
                ><button
                  v-if="!item.gradeKind"
                  :class="
                    buttonStyle(key, item[key].name)
                      ? 'buttonSelect'
                      : 'buttonUnSelect'
                  "
                  @click="siftScore(key, item[key].name)"
                >
                  <span style="padding: 0 10px">{{ levelName[key] }}</span>
                </button></span
              >
            </div>
            <div style="width: 100%; height: 60px"></div>
          </div>
          <div class="button-bg">
            <button
              class="buttonSelect"
              style="margin-right: 20px"
              @click="onSubmitWin()"
            >
              确定
            </button>
            <button
              class="buttonSelect"
              style="margin-right: 10px"
              @click="closeSwitchWin()"
            >
              取消
            </button>
          </div>
        </div>
        <div
          class="sift_window"
          style="background-color: rgba(0, 0, 0, 0.3)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import drawMixin from "../utils/drawMixin";
import {
  getTokenByCode,
  getScoreLevel,
  getSSEToken,
  getSSE,
  getTaskLab,
} from "@/api/common";
import { deleteChildren, listToTree } from "@/utils/dataTree";
import AMapLoader from "@amap/amap-jsapi-loader";
import busLeftDataWidthFit from "@/views/components/busLeftDataWidthFit";
import AreAndDepartmentRightDataWidthFit from "@/views/components/areAndDepartmentRightDataWidthFit";
import busDetail from "./components/busDetail.vue";

function getCode(variable) {
  const query = window.location.href;
  const vars = query.split("?");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

const levelToSize = {
  6: { size: 10000 },
  7: { size: 5120 },
  8: { size: 2560 },
  9: { size: 1280 },
  10: { size: 640 },
  11: { size: 320 },
  12: { size: 160 },
  13: { size: 90 },
  14: { size: 50 },
  15: { size: 35 },
  16: { size: 22 },
  17: { size: 12 },
  18: { size: 8 },
  19: { size: 5 },
  20: { size: 2 },
};

//全局地图
let mainMap = null;
//全局地图图层
let loca = null;

export default {
  components: {
    AreAndDepartmentRightDataWidthFit,
    busLeftDataWidthFit,
    busDetail,
  },
  mixins: [drawMixin],
  data() {
    return {
      //是否使用mock数据
      isMock: false,
      // 是否显示logo
      showLogo: true,
      // 显示标题
      largeScreenTitle: "",
      //计时器
      loadingTimeout: null,
      loopTimeOut: null,
      //地图数据
      UIOnShow: true,
      loading: false,
      defaultId: "", //初始化默认的地区id
      defaultUnitId: "", //初始化默认的单位id
      areaId: null, //选择器获取的地区id
      unitId: "", //选择器获取的单位id
      areaChooseWin: false, //选择器的状态
      userInfo: {}, //用户初始化信息
      areaData: [], //渲染选择器的地区树
      //右上角年月日
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      timing: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      //标题两侧的装饰颜色
      decorationColor: Object.freeze(["#568aea", "#000000"]),
      //地图传值
      toMapData: "",
      toMapUnit: "",
      areaPath: "", //选择区域后的path
      defaultAreaPath: "",
      //等级参数
      levelParam: {},
      levelName: {},
      //地图数值
      // mainMap: Object.freeze(null),
      mapDataGroup: {},
      levelGroup: {},
      //地图实例化及各子自有层
      // loca: Object.freeze(null),
      //默认地图搜索数据
      searchData: {
        coordinates: [113.056098, 23.682064],
        max_distance: 200000,
        territory_id: "",
        unit_id: "",
        grade_kind: 1,
        big_kind: [],
        task_lab_id: "",
      },
      //弹窗数据
      busInfoSwitch: false, // 商户详细信息弹窗
      siftSwitch: false, // 筛选弹窗开关
      gradeKind: 1, // 1评分制，2等级制
      grade_kind_grow: [], //获取所有制度
      bigKind: [], //单位不同行业类型
      bigKindControl: [], // 克隆bigKind，用于控制按钮状态
      isLastKind: false, //是否最后一个行业部门类型
      getMapDataLoading: false, //获取地图数据loading
      //地图推流
      SSEToken: "", //推流token
      taskPage: 1, //地图推流任务序号
      mapCount: 0, //累计刷新地图点
      isReload: false, //是否重载
      finishMapData: false, //是否加载完毕
      //任务标签
      taskLab: [],
      task_lab_id:'',
    };
  },
  created() {
    this.loading = true;
    if (!sessionStorage.getItem("Info")) {
      let code = getCode("code");
      getTokenByCode(code).then(async (res) => {
        if (res.code === 0) {
          sessionStorage.setItem("Info", JSON.stringify(res.result));
          this.userInfo = JSON.parse(sessionStorage.getItem("Info"));
          if (this.userInfo.territory_children) {
            let tempArea = [];
            this.userInfo.territory_children.map((item) => {
              if (item.unit_id !== "000000000000000000000000") {
                tempArea.push(item);
              }
            });
            this.areaData = deleteChildren(listToTree(tempArea));
          }
          this.defaultId = this.searchData.territory_id =
            this.userInfo.territory_id;
          this.defaultUnitId = this.searchData.unit_id = this.userInfo.unit_id;
          this.defaultAreaPath = this.userInfo.path;
          this.timeFn();
          this.init(this.defaultId, this.defaultUnitId);
          this.handleBigKind(this.userInfo.big_kind);
          await this.scoreLevel();
          await this.mapInit(this.defaultId, this.defaultUnitId);
          if (this.SSEToken === "") await this.getToken();
          this.initTaskLab();
        }
        this.setTitle();
      });
    }
    this.onKeyDown();
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    this.areaId = "";
    this.unitId = "";
    if (sessionStorage.getItem("Info")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("Info"));
      if (this.userInfo.territory_children) {
        let tempArea = [];
        this.userInfo.territory_children.map((item) => {
          if (item.unit_id !== "000000000000000000000000") {
            tempArea.push(item);
          }
        });
        this.areaData = deleteChildren(listToTree(tempArea));
      }
      this.defaultId = this.searchData.territory_id =
        this.userInfo.territory_id;
      this.defaultUnitId = this.searchData.unit_id = this.userInfo.unit_id;
      this.defaultAreaPath = this.userInfo.path;
      this.areaPath = this.userInfo.path;
      await this.init(this.defaultId, this.defaultUnitId);
      this.handleBigKind(this.userInfo.big_kind);
      this.setTitle();
      await this.scoreLevel();
      await this.mapInit(this.defaultId, this.defaultUnitId);
      if (this.SSEToken === "") await this.getToken();
      this.initTaskLab();
    }
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
      this.loadingTimeout = null;
    }
    this.loadingTimeout = setTimeout(() => {
      this.loading = false;
    }, 1400);
    this.timeFn();
    this.loopTimeOut = setTimeout(this.loopGetData, 300000);
  },
  beforeDestroy() {
    clearInterval(this.timing);
    clearTimeout(this.loadingTimeout);
    clearTimeout(this.loopTimeOut);
    sessionStorage.clear();
    mainMap && mainMap.destroy();
  },
  computed: {
    buttonStyle() {
      return (score_template, level) => {
        if (this.levelParam[score_template][level]) {
          return this.levelParam[score_template][level].invisible;
        }
        return true;
      };
    },
    bigKindButtonStyle() {
      return (id) => {
        if (this.bigKindControl.includes(id)) return true;
        return false;
      };
    },
    gradeKindName() {
      return (kind) => {
        if (kind === 1) return "评分制 ";
        if (kind === 2) return "等级制";
        return false;
      };
    },
    getShowPath() {
      return (current) => {
        if (current && current !== "" && current !== null) {
          let temp = this.userInfo.territory_children.find(
            (item) => item.id === current
          );
          if (temp && temp !== -1) {
            return temp.path;
          }
        }
        return this.userInfo.path;
      };
    },
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH:mm:ss");
        this.dateYear = formatTime(new Date(), "yyyy年MM月dd日");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    //判断地图传值和传path
    init(territory_id, unit_id) {
      this.toMapData = territory_id;
      this.toMapUnit = unit_id;
      this.areaPath = this.searchPath(
        territory_id,
        JSON.parse(sessionStorage.getItem("Info")).territory_children
      );
    },
    //地图初始化
    mapInit(territory_id, unit_id) {
      let that = this;
      that.searchData.territory_id = territory_id;
      that.searchData.unit_id = unit_id;
      AMapLoader.load({
        key: "0400f3b955c4941f868fe510bb1addf6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.PlaceSearch", "AMap.MassMarks", "AMap.Marker"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        Loca: {
          version: "2.0.0",
        },
      })
        .then((AMap) => {
          mainMap = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 16, //初始化地图级别
            pitch: 40,
            mapStyle: "amap://styles/dark", //标准样式
            features: ["bg", "road"],
            center: that.searchData.coordinates, //初始化地图中心点位置
          });
          //根据path定位地图中心点
          that.placeSearch = new AMap.PlaceSearch({});
          if (that.userInfo.code && that.userInfo.code !== "") {
            mainMap.setCity(that.userInfo.code);
          } else {
            that.placeSearch.search(that.areaPath, function (status, result) {
              if (status !== "error") {
                that.searchData.coordinates = [
                  result.poiList.pois[0].location.lng - 0.008,
                  result.poiList.pois[0].location.lat,
                ];
              }
              mainMap.setCenter(that.searchData.coordinates, true, 1);
            });
          }
          // 实例化Loca
          // eslint-disable-next-line no-undef
          loca = new Loca.Container({
            map: mainMap,
          });

          // 根据数据，批量生成点图层
          try {
            for (let key in that.levelParam) {
              for (let el in that.levelParam[key]) {
                if (that.levelParam[key][el]?.pointData?.features) {
                  let image =
                    that.levelParam[key][el].bi_image &&
                    that.levelParam[key][el].bi_image !== ""
                      ? that.levelParam[key][el].bi_image
                      : "breath_green";
                  // eslint-disable-next-line no-undef
                  that.levelParam[key][el].pointLayer = new Loca.ScatterLayer({
                    zIndex: that.levelParam[key][el].zIndex,
                    visible: that.levelParam[key][el].invisible,
                    zooms: [2, 20],
                  });

                  that.levelParam[key][el].pointDataLayer =
                    // eslint-disable-next-line no-undef
                    new Loca.GeoJSONSource({
                      data: that.levelParam[key][el].pointData,
                    });
                  that.levelParam[key][el].pointLayer.setSource(
                    that.levelParam[key][el].pointDataLayer
                  );
                  that.levelParam[key][el].pointLayer.setStyle({
                    unit: "meter",
                    size: [
                      that.levelParam[key][el].size,
                      that.levelParam[key][el].size,
                    ],
                    texture: require(`../assets/icon/${image}.png`),
                    duration: that.levelParam[key][el].duration,
                    animate: that.levelParam[key][el].animate,
                  });
                  loca.add(that.levelParam[key][el].pointLayer);
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
          loca.animate.start();

          //实例化文本事件
          const text = new AMap.Text({
            text: "纯文本标记",
            anchor: "left", // 设置文本标记锚点
            draggable: true,
            cursor: "pointer",
            angle: 0,
            visible: false,
            offset: [0, -90],
            style: {
              padding: "5px 10px",
              "margin-bottom": "1rem",
              "border-radius": ".25rem",
              "background-color": "rgba(0,0,0,0.5)",
              // 'width': '12rem',
              "border-width": 0,
              "box-shadow": "0 2px 6px 0 rgba(255, 255, 255, .3)",
              "text-align": "center",
              "font-size": "16px",
              color: "#fff",
            },
          });
          text.setMap(mainMap);
          //点击事件
          mainMap.on("click", function (e) {
            let feat = undefined;
            for (let key in that.levelParam) {
              for (let el in that.levelParam[key]) {
                if (
                  that.levelParam[key][el].pointLayer &&
                  that.levelParam[key][el].pointLayer.queryFeature(
                    e.pixel.toArray()
                  )
                ) {
                  feat = that.levelParam[key][el].pointLayer.queryFeature(
                    e.pixel.toArray()
                  );
                  that.onShowBusWin(feat.properties.bid, feat.properties.score);
                  break;
                }
              }
            }
          });
          //鼠标移入事件，
          mainMap.on("mousemove", function (e) {
            let feat = undefined;
            for (let key in that.levelParam) {
              for (let el in that.levelParam[key]) {
                if (
                  that.levelParam[key][el].pointLayer &&
                  that.levelParam[key][el].pointLayer.queryFeature(
                    e.pixel.toArray()
                  )
                ) {
                  feat = that.levelParam[key][el].pointLayer.queryFeature(
                    e.pixel.toArray()
                  );
                  break;
                }
              }
            }
            if (feat) {
              let name = feat.properties.short_name
                ? feat.properties.short_name
                : feat.properties.name;
              if (feat.properties.score === -1) {
                name += " , 到访不遇";
              } else if (feat.properties.score === -2) {
                name += " , 闭店";
              } else {
                name +=
                  "<br />" +
                  feat.properties.score / 100 +
                  "分，" +
                  feat.properties.score_level +
                  "级";
              }
              text.setText(name);
              text.setPosition(e.lnglat);
              text.show();
            } else {
              text.hide();
            }
          });
          //地图尺寸变更事件，缩放结束时获取地图缩放等级更新点尺寸
          mainMap.on("zoomend", function () {
            const level = Math.round(mainMap.getZoom());
            if (level >= 6 && level <= 20) {
              for (let key in that.levelParam) {
                for (let el in that.levelParam[key]) {
                  if (that.levelParam[key][el].bi_image === "breath_red") {
                    that.levelParam[key][el].size =
                      levelToSize[level].size * 2.8 * 2.8;
                  } else if (
                    that.levelParam[key][el].bi_image === "breath_yellow"
                  ) {
                    that.levelParam[key][el].size =
                      levelToSize[level].size * 2.8;
                  } else {
                    if (that.levelParam[key][el].size) {
                      that.levelParam[key][el].size = levelToSize[level].size;
                    }
                  }
                }
              }
              that.siftScore();
            }
            if (level > 16) {
              mainMap.setFeatures(["bg", "point", "road", "building"]);
            } else {
              mainMap.setFeatures(["bg", "road"]);
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //循环定时器
    loopGetData() {
      if (this.loopTimeOut) {
        clearTimeout(this.loopTimeOut);
        this.loopTimeOut = null;
      }
      //重新获取地图数据
      this.getToken();

      //重新获取左侧数据
      if (this.$refs.busData) {
        this.$refs.busData.init(this.toMapData, this.toMapUnit);
      }
      //重新获取右侧数据
      if (this.$refs.areaAndDep) {
        this.$refs.areaAndDep.init(this.toMapData, this.toMapUnit);
      }
      this.loopTimeOut = setTimeout(this.loopGetData, 300000);
    },
    //商事主体弹窗显示及内容显示
    onShowBusWin(id, score) {
      this.busInfoSwitch = true;
      this.$nextTick(() => {
        if (this.$refs.busDetailWin) {
          this.$refs.busDetailWin.init(id, this.gradeKind, score);
        }
      });
    },
    //关闭商事主体弹窗
    onCloseBusWin() {
      this.busInfoSwitch = false;
    },
    //根据地区id查找当前的地区路径（path）
    searchPath(id, item) {
      let tempData = item.find((el) => el.id === id);
      if (tempData && tempData !== "") {
        return tempData.path;
      } else {
        return this.userInfo.path;
      }
    },
    //根据地区id查找当前的unit_id
    searchUnitId(id, item) {
      let tempData = item.find((el) => el.id === id);
      if (tempData && tempData !== "") {
        return tempData.unit_id;
      } else {
        return this.userInfo.unit_id;
      }
    },
    // 重载地图点
    reloadMapData() {
      if (Object.keys(this.levelParam).length === 0)
        return console.log("等级参数为空！");
      try {
        for (let key in this.levelParam) {
          for (let el in this.levelParam[key]) {
            if (this.levelParam[key][el].pointDataLayer) {
              this.levelParam[key][el].pointDataLayer.destroy();
              // this.levelParam[key][el].pointLayer.destroy();
              // eslint-disable-next-line no-undef
              this.levelParam[key][el].pointDataLayer = new Loca.GeoJSONSource({
                data: this.levelParam[key][el].pointData,
              });
              this.levelParam[key][el].pointLayer.setSource(
                this.levelParam[key][el].pointDataLayer
              );
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    //地图缩放
    mapZoom(type) {
      let zoom = mainMap.getZoom();
      if (type === "in") {
        if (zoom < 20) {
          zoom++;
          mainMap.setZoom(zoom);
        }
      } else {
        if (zoom > 3) {
          zoom--;
          mainMap.setZoom(zoom);
        }
      }
    },
    // 根据按钮判断数据
    siftScore(score_template, level) {
      const that = this;
      if (score_template && level) {
        that.levelParam[score_template][level].invisible =
          !that.levelParam[score_template][level].invisible;
        if (that.levelParam[score_template][level].invisible) {
          that.levelParam[score_template][level].pointLayer.show();
        } else {
          that.levelParam[score_template][level].pointLayer.hide();
        }
        return;
      }
      for (let key in that.levelParam) {
        for (let el in that.levelParam[key]) {
          if (that.levelParam[key][el].pointLayer) {
            if (that.levelParam[key][el].invisible) {
              let image =
                that.levelParam[key][el].bi_image &&
                that.levelParam[key][el].bi_image !== ""
                  ? that.levelParam[key][el].bi_image
                  : "breath_green";
              that.levelParam[key][el].pointLayer.setStyle({
                unit: "meter",
                size: [
                  that.levelParam[key][el].size,
                  that.levelParam[key][el].size,
                ],
                texture: require(`../assets/icon/${image}.png`),
                duration: that.levelParam[key][el].duration,
                animate: that.levelParam[key][el].animate,
              });
            } else {
              that.levelParam[key][el].pointLayer.hide();
            }
          }
        }
      }
    },
    //监听键盘案件切换mock模式和logo显示
    onKeyDown() {
      document.onkeydown = (e) => {
        if (e.code === "KeyL" && e.shiftKey === true) {
          this.showLogo = !this.showLogo;
        }
      };
    },
    // 设置大屏标题
    setTitle() {
      if (this.areaId && this.areaId !== "") {
        let pathData = this.userInfo.territory_children.find(
          (item) => item.id === this.areaId
        );
        if (pathData.regional === "镇街") {
          let parentPathData = this.userInfo.territory_children.find(
            (item) => item.id === pathData.parent_id
          );
          if (parentPathData) {
            this.largeScreenTitle =
              parentPathData.path.replace(new RegExp("/", "g"), "") +
              "智慧消防协同共治管理平台";
            return;
          }
        }
        this.largeScreenTitle =
          pathData.path.replace(new RegExp("/", "g"), "") +
          "智慧消防协同共治管理平台";
        return;
      }
      this.largeScreenTitle =
        this.userInfo.path.replace(new RegExp("/", "g"), "") +
        "智慧消防协同共治管理平台";
    },
    // 获取本单位正在用的排查评分模板，并形成数据结构
    async scoreLevel() {
      getScoreLevel().then((res) => {
        if (res.code !== 0) return console.log("等级参数有误！");
        res.result.forEach((item) => {
          //遍历其他模板
          this.levelName[item.id] = item.name;
          if (item.level_param && item.level_param.length !== 0) {
            let level_param = {};
            item.level_param.forEach((el, index) => {
              let temp_animate = false;
              let temp_duration = 0;
              let temp_zIndex = 1;
              if (el.bi_image === "breath_red") {
                temp_animate = true;
                temp_duration = 500;
                temp_zIndex = 900;
              } else if (el.bi_image === "breath_yellow") {
                temp_animate = true;
                temp_duration = 1000;
                temp_zIndex = 800;
              } else {
                temp_zIndex = (index + 1) * 50;
              }
              let temp = {
                name: el.name,
                bi_image: el.bi_image,
                size: el.size,
                pointLayer: null,
                pointDataLayer: null,
                pointData: {
                  type: "FeatureCollection",
                  features: [],
                },
                invisible: true,
                zIndex: temp_zIndex, //图层排序
                duration: temp_duration, // 动画持续时间
                animate: temp_animate, //是否开启动画，仅针对后面的点
              };
              level_param[el.name] = temp;
            });
            this.levelParam[item.id] = level_param;
            this.levelParam[item.id].gradeKind = item.kind;
          }
          //获取评分制等机制按钮
          if (!this.grade_kind_grow.includes(item.kind)) {
            this.grade_kind_grow.push(item.kind);
          }
        });
        //到访不遇
        this.levelName["noVisit"] = "到访不遇";
        this.levelParam["noVisit"] = {};
        this.levelParam.noVisit["noVisit"] = {
          name: "noVisit",
          bi_image: "breath_no_visits",
          size: 15,
          pointLayer: null,
          pointDataLayer: null,
          pointData: {
            type: "FeatureCollection",
            features: [],
          },
          invisible: true,
          zIndex: 50, //图层排序
          duration: 0, // 动画持续时间
          animate: false, //是否开启动画，仅针对后面的点
        };
        //闭店
        this.levelName["closed"] = "闭店";
        this.levelParam["closed"] = {};
        this.levelParam.closed["closed"] = {
          name: "closed",
          bi_image: "breath_no_visits",
          size: 15,
          pointLayer: null,
          pointDataLayer: null,
          pointData: {
            type: "FeatureCollection",
            features: [],
          },
          invisible: true,
          zIndex: 50, //图层排序
          duration: 0, // 动画持续时间
          animate: false, //是否开启动画，仅针对后面的点
        };
      });
    },
    //处理Big_kind
    handleBigKind(big_kind) {
      this.bigKind = [];
      big_kind.forEach((item) => {
        let temp = { id: item._id, name: item.name };
        this.bigKind.push(temp);
        this.bigKindControl.push(item._id);
      });
      this.searchData.big_kind = this.bigKindControl;
    },
    // 切换评分制度按钮操作，切换后更新地图点
    switchRegulation(kind) {
      if (this.gradeKind === kind) return; //拦截重复点击
      this.gradeKind = kind;
    },
    // 切换行业部门类型操作
    switchDepartment(kind) {
      this.isLastKind = false; //复位额外提示
      let temp = this.bigKindControl.findIndex((el) => el === kind);
      if (temp !== -1) {
        //找到就删除
        if (this.bigKindControl.length === 1) return (this.isLastKind = true); //最后一个不删除，显示提示
        //不是最后一个就删，并更新入参
        this.bigKindControl.splice(temp, 1);
      } else {
        //找不到就加进去，并更新入参
        this.bigKindControl.push(kind);
      }
    },
    //清空levelParam的地图点数据，但是不清除图层
    cleanLevelParam() {
      for (let key in this.levelParam) {
        for (let el in this.levelParam[key]) {
          if (this.levelParam[key][el].pointData?.features) {
            this.levelParam[key][el].pointData.features = [];
          }
        }
      }
    },
    //获取地图点token
    getToken() {
      getSSEToken(this.searchData).then(async (res) => {
        if (res.code !== 0) return console.log("推流token获取失败！");
        this.SSEToken = res.result;
        await this.cleanLevelParam();
        await this.reloadMapData();
        if (this.isReload && !this.finishMapData) await setTimeout(10000);
        this.taskPage = 1;
        await this.connectMapData(this.SSEToken, this.taskPage);
      });
    },
    //获取地图推流
    connectMapData(token, page) {
      if (this.isReload && !this.finishMapData) {
        this.isReload = false;
        console.log("重载开始，停正在获取地图");
        return;
      }
      getSSE(token, page).then(async (res) => {
        if (res.code === 0 && res.result !== "done") {
          //处理数据
          try {
            res.result.map((item) => {
              if (
                item.geometry &&
                item.properties.param_id &&
                item.properties.score_level
              ) {
                item.id = item.properties.bid;
                if (item.properties.score === -1) {
                  this.levelParam["noVisit"]["noVisit"].pointData.features.push(
                    item
                  );
                } else if (item.properties === -2) {
                  this.levelParam["closed"]["closed"].pointData.features.push(
                    item
                  );
                } else {
                  this.levelParam[item.properties.param_id][
                    item.properties.score_level
                  ].pointData.features.push(item);
                }
              } else {
                console.log('无效点');
              }
            });
          } catch (err) {
            console.log(err);
          }

          this.mapCount += res.count;
          if (this.mapCount >= 3000) {
            this.reloadMapData();
            this.mapCount = 0;
          }
          this.taskPage += 1;
          this.connectMapData(this.SSEToken, this.taskPage);
        } else {
          console.log("结束");
          this.finishMapData = true;
          this.reloadMapData();
        }
      });
    },
    //关闭取消弹窗
    closeSwitchWin() {
      this.bigKindControl = this.searchData.big_kind;
      this.gradeKind = this.searchData.grade_kind;
      this.areaId = this.searchData.territory_id;
      this.siftSwitch = false;
    },
    //确认弹窗内容
    onSubmitWin() {
      if (
        this.searchData.grade_kind === this.gradeKind &&
        this.searchData.big_kind === this.bigKindControl &&
        this.searchData.task_lab_id === this.task_lab_id &&
        this.searchData.territory_id ===
          (this.areaId != null ? this.areaId : this.defaultId)
      )
        return (this.siftSwitch = false);
      this.searchData.grade_kind = this.gradeKind;
      this.searchData.task_lab_id = this.task_lab_id;
      this.searchData.big_kind = this.bigKindControl;
      this.searchData.territory_id = this.toMapData =
        this.areaId != null ? this.areaId : this.defaultId;
      let temp = this.userInfo.territory_children.find(
        (item) => item.id === this.searchData.territory_id
      );
      try {
        let code = "";
        let path = "";
        if (temp && temp !== -1) {
          code = temp.code;
          path = temp.path;
        } else {
          code = this.userInfo.code;
          path = this.userInfo.path;
        }
        if (code !== "" && code) {
          mainMap.setCity(code);
        } else {
          this.placeSearch.search(path, (status, result) => {
            if (status !== "error") {
              this.searchData.coordinates = [
                result.poiList.pois[0].location.lng - 0.008,
                result.poiList.pois[0].location.lat,
              ];
            }
            mainMap.setCenter(this.searchData.coordinates, true, 1);
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.isReload = this.finishMapData ? false : true;
      this.finishMapData = false;
      this.getToken();
      this.siftSwitch = false;
    },
    initTaskLab() {
      getTaskLab().then((res) => {
        if (res.code === 0) {
          this.taskLab = res.result;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index2.scss";
</style>
<style>
.bg-color {
  background-color: #020308;
}

.bg {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
  z-index: 200;
}

.select-left {
  position: absolute;
  top: 20px;
  left: 0;
}

.select-right {
  position: absolute;
  top: 20px;
  right: 0;
}

.select-width {
  width: 300px;
  top: -11px;
  font-size: 20px;
}

.select-width input {
  height: 16px !important;
}

.el-input__inner {
  font-size: 16px;
  color: #d3d6dd;
  border: 0 solid #d3d6dd !important;
  background: #020308 !important;
}

#container {
  top: 2%;
  transform-origin: left top;
}

.mapButtonIn {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 26%;
  bottom: 1%;
}

.mapButtonOut {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 1%;
  bottom: 1%;
}

.buttonSelect {
  background-color: #0093ff;
  color: white;
  min-width: 60px;
  height: 35px;
  margin: 20px 5px;
  border: 0;
  border-radius: 3px;
}

.buttonUnSelect {
  min-width: 60px;
  height: 35px;
  margin: 20px 5px;
  border: 0;
  border-radius: 3px;
}

.mapZoomButton {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 1px;
}

.winBg {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 990;
}

.busWin {
  margin: 20% auto auto auto;
  width: 60%;
  background-color: black;
  text-align: center;
  color: white;
  z-index: 993;
}

.busTitle {
  padding: 60px 0 20px 0;
}

.busContent {
  display: flex;
  font-size: 14px;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0 60px 0;
  width: 60%;
  margin: auto;
  word-break: break-all;
}

.busImage {
  width: 300px;
  height: 300px;
  padding-right: 10px;
  z-index: 996;
}

.busEmptyImage {
  width: 300px;
  height: 300px;
  border: solid 1px #66b1ff;
  line-height: 200px;
}

.busText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
}

.sg_logoIn {
  position: absolute;
  right: 490px;
  top: 70px;
  width: 200px;
  height: 71px;
  opacity: 0.6;
  z-index: 20;
}
.sg_logoOut {
  position: absolute;
  right: 0;
  top: 70px;
  width: 200px;
  height: 71px;
  opacity: 0.6;
  z-index: 20;
}
.sift_window {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.sift_card {
  position: relative;
  margin: auto;
  top: 30%;
  background-color: black;
  width: 600px;
  height: 500px;
  border: solid #3f8cff;
  z-index: 999;
}
.sift_sub_block {
  border-bottom: solid rgba(64, 164, 251, 0.5);
  margin: 0 10px 20px 10px;
}
.sift_sub_block_title {
  margin: 10px 10px 0 10px;
  font-weight: bold;
  font-size: larger;
}

.button-bg {
  position: absolute;
  bottom: 1px;
  display: flex;
  justify-content: right;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 5%,
    10%,
    rgba(0, 0, 0, 1)
  );
}
.mapLoadingText_in {
  position: absolute;
  bottom: 0px;
  left: 26%;
}
.mapLoadingText_out {
  position: absolute;
  bottom: 0px;
  left: 1%;
}

button {
  cursor: pointer;
}
</style>
