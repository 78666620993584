/**
 * 传入一维数据，根据p_id，children序列化树数据
 * @param list 树数据
 */
export function listToTree(list) {
    // 1. 定义两个变量
    let map = {},  tree = []
    // 2. 建立一个映射关系，并给每个元素补充children属性.
    // 映射关系: 目的是让我们能通过id快速找到对应的元素
    // 补充children：让后边的计算更方便
    list.forEach(item => {
        if (!item.children) {
            item.children = []
        }
        map[item.id] = item
    })

    list.forEach(item => {
        // 对于每一个元素来说，先找它的上级
        //    如果能找到，说明它有上级，则要把它添加到上级的children中去
        //    如果找不到，说明它没有上级，直接添加到 treeList
        const parent = map[item.parent_id]
        // 如果存在上级则表示item不是最顶层的数据
        if (parent) {
            parent.children.push(item)
        } else {
            // 如果不存在上级 则是顶层数据,直接添加
            tree.push(item)
        }
    })

    return tree;
}

/**
 * 传入children序列化后的树数据，删除空的children
 * @param arr 树数据
 */
export function deleteChildren(arr) {
    let child = arr
    for (let i = child.length; i--; i > 0) {
        if (child[i].children) {
            if (child[i].children.length) {
                deleteChildren(child[i].children)
            } else {
                delete child[i].children
            }
        }
    }
    return arr
}

